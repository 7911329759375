<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Discount Groups</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-tabs background-color="accent" color="white">
            <v-tab to="/inventory/discount-groups">Items</v-tab>
            <v-tab to="/inventory/discount-group/item-groups"
              >Item Groups</v-tab
            >
          </v-tabs>
        </v-row>
        <v-row>
          <router-view />
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>